<template>
  <v-container fluid>
    <editor
      :pageMode="pageMode"
      :pageTitle="pageTitle"
      :authUsers="authUsers"
      :createStatus="createStatus"
      :initial="dataItem"
      @data="save"
    ></editor>
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      pageMode: "view",
      pageTitle: "Approval Stage",
      approvals: [],
      authUsers: [],
      dataItem: {},
      createStatus: true,
      selectedApproval: {},
      userHeaders: [{ text: "Name", value: "users.name" }],
    };
  },
  watch: {
    "$route.params.id": {
      handler: "approvalById",
      immediate: true,
    },
  },
  methods: {
    approvalById(id) {
      const self = this;
      this.$store
        .dispatch("get", `/approval_stages/${id}`)
        .then((res) => {
          self.dataItem = res.ResponseData;
          const wst1 = res.ResponseData.wst1;
          const users = wst1.map((record) => {
            return { id: record.users.id, name: record.users.name };
          });
          self.authUsers = users;
          self.createStatus = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    save(data) {
      const id = this.dataItem.id;
      const url = `/approval_stages/${id}`;
      const self = this;
      this.$store
        .dispatch("put", { url, data })
        .then(() => {
          self.$router.push("/approval-stages");
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
